import { AccountMixin } from '../AccountCore'

export interface Workspace {
  isActive: boolean
  isPaying: boolean
  id: string
  displayName: string
  domain: string
  userIsAdmin: boolean
  userIsExternalMember: boolean
  inviteByUrlId: string | null
  logoUrl: string | undefined
  maySetLogoUrl: boolean
  setLogoUrl: (
    type: 'logo' | 'watermarkLogo' | 'sharePageLogo',
    url: string
  ) => Promise<void>
}

export const workspace: AccountMixin<Workspace> = (
  core,
  { userProfile, team, plan }
) => ({
  get isActive() {
    return plan === 'Growth' || plan === 'Growth Trial'
  },

  get isPaying() {
    return plan === 'Growth'
  },

  get id() {
    return team?.id ?? ''
  },

  get displayName() {
    return team?.name ?? ''
  },

  get domain() {
    if (!team) return ''

    // Almost all teams have `team.id` === `team.group`.
    // In the rare case that `team.id` !== `team.group`,
    // it means `team.group` is the domain they've chosen to use
    // and `team.id` is the domain they signed up with originally.
    return team.group
  },

  get userIsAdmin() {
    return !!userProfile.isTeamAdmin
  },

  get userIsExternalMember() {
    return (
      core.userEmailGroup !== this.domain && userProfile.group === this.domain
    )
  },

  get inviteByUrlId() {
    return team?.inviteByUrlId ?? null
  },

  get logoUrl() {
    return team?.logo
  },

  get maySetLogoUrl() {
    return !!userProfile.isTeamAdmin
  },

  async setLogoUrl(type, url) {
    if (!team) {
      return Promise.resolve()
    }

    const response = await core.fetchWithToken(
      'POST',
      `/api/team/update-logo`,
      { type, url }
    )
    const result = await response.json()
    if (result.error) {
      throw Error(result.error)
    }
  },
})
